import { LanguageCode } from 'utils/enum'
import { formatNumberByLocalization } from 'utils/handler/rupiah'
import {
  formatBillionPoint,
  formatPriceNumber,
  formatPriceNumberThousandDivisor,
} from 'utils/handler/number'
import { hundred, million, ten } from 'utils/helpers/const'
import { availableList, availableListColors } from 'config/AvailableListColors'
import { RoundingStrategy } from 'utils/types/models'
import { CarRecommendation, CarVariant } from 'utils/types'
import {
  CalculatorResultV3,
  PromoItemTypeV3,
  SpecialRateListWithPromoTypeV3,
} from 'utils/types/utils'

type DpObject = {
  dpAmount: number
}
type MonthlyInstallmentObject = {
  monthlyInstallment: number
}
export type CarModel = Pick<
  CarRecommendation,
  'lowestAssetPrice' | 'highestAssetPrice'
>
type CarModelWithBrandAndModel = Pick<CarRecommendation, 'brand' | 'model'>
type PriceValueObject = Pick<CarVariant, 'priceValue'>

export const formatPriceRange = (
  prices: number[],
  digits = ten,
  languageCode = LanguageCode.en,
) => {
  const minPrice = formatNumberByLocalization(
    Math.min(...prices),
    languageCode,
    million,
    digits,
    RoundingStrategy.Round,
  )

  const maxPrice = formatNumberByLocalization(
    Math.max(...prices),
    languageCode,
    million,
    digits,
    RoundingStrategy.Round,
  )

  return `${formatBillionPoint(minPrice)} - ${formatBillionPoint(maxPrice)}`
}

export const getModelPriceRange = (
  carModel: CarModel,
  languageId = LanguageCode.en,
) => {
  const lowestAssetPrice = formatPriceNumber(carModel.lowestAssetPrice)
  const highestAssetPrice = formatPriceNumber(carModel.highestAssetPrice)

  const lowerPrice =
    lowestAssetPrice >= 1000
      ? formatPriceNumberThousandDivisor(lowestAssetPrice, languageId)
      : lowestAssetPrice

  const higherPrice =
    highestAssetPrice >= 1000
      ? formatPriceNumberThousandDivisor(highestAssetPrice, languageId)
      : highestAssetPrice

  return `${lowerPrice}-${higherPrice}`
}
export const getVariantsPriceRange = (
  variants: PriceValueObject[],
  languageCode = LanguageCode.id,
) => {
  return formatPriceRange(
    variants.map((variant) => variant.priceValue),
    hundred,
    languageCode,
  )
}

export const getMinimumMonthlyInstallment = (
  variants: MonthlyInstallmentObject[],
  language: LanguageCode,
  divider: number,
  digits: number,
) => {
  const prices = variants?.map((variant) => variant.monthlyInstallment) || []
  return formatNumberByLocalization(
    Math.min(...prices),
    language,
    divider,
    digits,
  )
}

export const getMinimumDp = (
  variants: DpObject[],
  language: LanguageCode,
  divider: number,
  digits: number,
) => {
  const prices = variants.map((variant) => variant.dpAmount)
  return formatNumberByLocalization(
    Math.min(...prices),
    language,
    divider,
    digits,
  )
}

export const getLowestInstallment = (variants: MonthlyInstallmentObject[]) => {
  const prices = variants.map((variant) => variant.monthlyInstallment)
  return Math.min(...prices)
}

export const getCarBrand = (brand: string | string[] | undefined) => {
  if (String(brand).toLowerCase() === 'toyota') {
    return 'Toyota'
  } else if (String(brand).toLowerCase() === 'daihatsu') {
    return 'Daihatsu'
  } else if (String(brand).toLowerCase() === 'bmw') {
    return 'BMW'
  } else if (String(brand).toLowerCase() === 'isuzu') {
    return 'Isuzu'
  } else if (String(brand).toLowerCase() === 'peugeot') {
    return 'Peugeot'
  } else if (String(brand).toLowerCase() === 'hyundai') {
    return 'Hyundai'
  } else if (String(brand).toLowerCase() === 'honda') {
    return 'Honda'
  } else if (String(brand).toLowerCase() === 'suzuki') {
    return 'Suzuki'
  } else if (String(brand).toLowerCase() === 'mitsubishi') {
    return 'Mitsubishi'
  } else {
    return 'Other'
  }
}

export const getColorList = (
  brand?: string | string[],
  model?: string | string[],
) => {
  const carBrandModelUrl = `/${brand}/${model}`.toLowerCase()

  if (availableList.includes(carBrandModelUrl)) {
    const colorsTmp = availableListColors.filter(
      (url) => url.url === carBrandModelUrl,
    )[0].colors

    if (colorsTmp.length === 0) return []
    return colorsTmp
  }

  return ['#000000']
}

export const generateAllPromosForBadge = (
  calculator?: SpecialRateListWithPromoTypeV3,
) => {
  let tempAllPromoArr: PromoItemTypeV3[] = []
  let tempAllCalculatorArr: CalculatorResultV3[] = []

  if (calculator) {
    for (let i = 0; i <= calculator?.calculatorArr.length; i++) {
      const currentCalculatorArr = calculator?.calculatorArr[i]
      if (currentCalculatorArr) {
        tempAllCalculatorArr.push(currentCalculatorArr)
        for (let j = 0; j < currentCalculatorArr.all_promos?.length; j++) {
          tempAllPromoArr.push(currentCalculatorArr.all_promos[j])
        }
      }
    }
  }

  const filteredAllPromoArr = tempAllPromoArr.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.promo_id === value.promo_id),
  )

  const generatedArrFromPromo = filteredAllPromoArr.map((i) => ({
    title: i.promo_name,
    body: [{ body: i.promo_desc }],
    snk: i.promo_url,
  }))

  const generatedArrFromCalculator = tempAllCalculatorArr.map((i) => ({
    title: i.calculator_name,
    body: [{ body: i.calculator_description ?? '' }],
    snk: i.calculator_url ?? '',
  }))

  return generatedArrFromCalculator.concat(generatedArrFromPromo)
}
