import { Modal } from 'antd'
import React, {
  TextareaHTMLAttributes,
  useEffect,
  useMemo,
  useState,
} from 'react'
import type { ModalProps } from 'antd/lib/modal'
import { colors } from 'utils/helpers/style/colors'
import { Button, IconClose } from 'components/atoms'
import styles from '../../../styles/components/organisms/popupPromo.module.scss'
import elementId from 'utils/helpers/elementId/elementIds'
import { PopupPromoDataItemType, trackDataCarType } from 'utils/types/utils'
import { trackEventCountly } from 'services/countly/countly'
import { CountlyEventNames } from 'services/countly/eventNames'
import { getLocalStorage } from 'utils/handler/localStorage'
import { LocalStorageKey, SessionStorageKey } from 'utils/enum'
import { LoanRank } from 'utils/types/models'
import { getSessionStorage } from 'utils/handler/sessionStorage'
import { getBrandAndModelValue } from 'utils/handler/getBrandAndModel'
import { ButtonSize, ButtonVersion } from 'components/atoms/button'
import clsx from 'clsx'

const initPromoList: PopupPromoDataItemType[] = [
  {
    title: 'Promo Cashback Rp2 Juta',
    body: [
      {
        title: '',
        // body: 'Dapatkan cashback 2 juta rupiah berlaku untuk pembelian mobil penumpang Toyota, Daihatsu, Isuzu, BMW, Peugeot, Hyundai melalui ACC dan TAF.',
        body: 'Dapatkan cashback 2 juta rupiah berlaku untuk pembelian mobil penumpang Toyota, Daihatsu, Isuzu, BMW, Hyundai melalui ACC dan TAF.',
      },
    ],
    snk: 'https://www.seva.id/info/promo/beli-mobil-di-seva-pasti-dapat-extra-cashback-rp2-juta-rupiah/',
  },
]

type PopupPromo = Omit<ModalProps, 'children'> & {
  data?: PopupPromoDataItemType[]
  additionalContainerClassname?: string
  onClickSNK?: (promo: PopupPromoDataItemType) => void
  carData?: any
  isShowCta?: boolean
  onClickCta?: () => void
  isLoadingCta?: boolean
}

export const PopupPromo = ({
  data,
  onClickSNK,
  additionalContainerClassname = '',
  carData,
  isShowCta,
  onClickCta,
  isLoadingCta,
  ...props
}: PopupPromo) => {
  const [promoList, setPromoList] = useState(initPromoList)
  const filterStorage: any = getLocalStorage(LocalStorageKey.CarFilter)

  const dataCar: trackDataCarType | null = getSessionStorage(
    SessionStorageKey.PreviousCarDataBeforeLogin,
  )
  const IsShowBadgeCreditOpportunity = getSessionStorage(
    SessionStorageKey.IsShowBadgeCreditOpportunity,
  )
  const isUsingFilterFinancial =
    !!filterStorage?.age &&
    !!filterStorage?.downPaymentAmount &&
    !!filterStorage?.monthlyIncome &&
    !!filterStorage?.tenure

  const trackClickPromoSK = (promoDetail: string, promoOrder: number) => {
    trackEventCountly(CountlyEventNames.WEB_PROMO_SK_CLICK, {
      CAR_BRAND: getBrandAndModelValue(carData?.brand),
      CAR_MODEL: getBrandAndModelValue(carData?.model),
      CAR_ORDER: carData?.carOrder,
      PROMO_DETAILS: promoDetail,
      PROMO_ORDER: promoOrder + 1,
      PELUANG_KREDIT_BADGE:
        isUsingFilterFinancial &&
        IsShowBadgeCreditOpportunity &&
        carData?.loanRank === 'Green'
          ? 'Mudah disetujui'
          : isUsingFilterFinancial &&
            IsShowBadgeCreditOpportunity &&
            carData?.loanRank === 'Red'
          ? 'Sulit disetujui'
          : 'Null',
      PAGE_ORIGINATION: 'PLP',
    })
  }
  useEffect(() => {
    if (data) {
      setPromoList(data)
    }
  }, [data])

  const lastIndex = useMemo(() => {
    return promoList.length - 1
  }, [promoList])

  return (
    <Modal
      title={
        <Title data-testid={elementId.PLP.Text.JudulPopupPromo}>Promo</Title>
      }
      closeIcon={
        <IconClose
          width={24}
          height={22}
          color={colors.primaryBlack}
          datatestid={elementId.PLP.Close.Button.PopupPromo}
        />
      }
      footer={null}
      className="custom-modal-promo"
      width={343}
      centered
      {...props}
    >
      <div
        className={clsx({
          [styles.container]: true,
          [styles.maxHeight474px]: !isShowCta,
          [additionalContainerClassname]: !!additionalContainerClassname,
        })}
      >
        {promoList.map((item, index) => (
          <div key={index} className={styles.contentPromo}>
            <span className={styles.titlePromo}>{item.title}</span>
            <div className={styles.bodyWrapper}>
              {item.body.map((body, idx) => (
                <div key={idx} className={styles.bodyContentWrapper}>
                  {body.title && (
                    <span className={styles.bodyTitle}>{body.title}</span>
                  )}
                  <span className={styles.bodyPromo}>{body.body}</span>
                </div>
              ))}
            </div>
            {!!item.snk ? (
              <a
                className={styles.snk}
                style={{
                  color: '#246ed4',
                }}
                href={item.snk}
                target="_blank"
                rel="noopener noreferrer"
                data-testid={elementId.PLP.Button.LihatSNK}
                onClick={() => {
                  onClickSNK && onClickSNK(item)
                  trackClickPromoSK(item.title, index)
                }}
              >
                {' '}
                S&K berlaku
              </a>
            ) : (
              <span
                className={styles.snk}
                style={{ color: '#13131B' }}
                data-testid={elementId.PLP.Button.LihatSNK}
              >
                {' '}
                S&K berlaku
              </span>
            )}
            {index !== lastIndex && <div className={styles.divider}></div>}
          </div>
        ))}
      </div>
      {isShowCta ? (
        <div className={styles.ctaWrapper}>
          <Button
            version={ButtonVersion.PrimaryDarkBlue}
            size={ButtonSize.Big}
            onClick={() => {
              onClickCta && onClickCta()
            }}
            loading={isLoadingCta}
          >
            Gunakan Promo
          </Button>
        </div>
      ) : (
        <></>
      )}
    </Modal>
  )
}

const Title = ({
  children,
  ...props
}: TextareaHTMLAttributes<HTMLHeadingElement>) => (
  <h2 className={styles.title} {...props}>
    {children}
  </h2>
)
